.TokenBase {
  position: relative;
  display: inline-flex;
  font-family: inherit;
  font-weight: var(--base-text-weight-semibold);
  text-decoration: none;
  white-space: nowrap;
  border-radius: var(--borderRadius-full);
  align-items: center;
}

.TokenBase:where([data-cursor-is-interactive='true']) {
  cursor: pointer;
}

.TokenBase:where([data-cursor-is-interactive='false']) {
  cursor: auto;
}

.TokenBase:where([data-size='small']) {
  width: auto;
  height: 16px;
  padding-right: var(--base-size-4);
  padding-left: var(--base-size-4);
  font-size: var(--text-body-size-small);
  /* stylelint-disable-next-line primer/typography */
  line-height: 16px;
}

.TokenBase:where([data-size='medium']) {
  width: auto;
  height: 20px;
  padding-right: var(--base-size-8);
  padding-left: var(--base-size-8);
  font-size: var(--text-body-size-small);
  /* stylelint-disable-next-line primer/typography */
  line-height: 20px;
}

.TokenBase[data-size='large'] {
  width: auto;
  height: 24px;
  padding-right: var(--base-size-8);
  padding-left: var(--base-size-8);
  font-size: var(--text-body-size-small);
  /* stylelint-disable-next-line primer/typography */
  line-height: 24px;
}

.TokenBase[data-size='xlarge'] {
  width: auto;
  height: 32px;
  padding-top: 0;
  padding-right: var(--base-size-16);
  padding-bottom: 0;
  padding-left: var(--base-size-16);
  font-size: var(--text-body-size-medium);
  /* stylelint-disable-next-line primer/typography */
  line-height: 32px;
}
