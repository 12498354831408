.KeybindingHint {
  position: relative;
  padding: 0;
  overflow: visible;
  font-family: inherit;
  font-size: inherit;
  /* stylelint-disable-next-line primer/typography */
  line-height: unset;
  color: inherit;
  white-space: nowrap;
  vertical-align: baseline;
  background: none;
  border: none;
  box-shadow: none;
}
