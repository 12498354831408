:where(.Heading) {
  margin: 0;
  font-size: var(--text-title-size-large);
  font-weight: var(--base-text-weight-semibold);

  &:where([data-variant='large']) {
    font: var(--text-title-shorthand-large);
  }

  &:where([data-variant='medium']) {
    font: var(--text-title-shorthand-medium);
  }

  &:where([data-variant='small']) {
    font: var(--text-title-shorthand-small);
  }
}
