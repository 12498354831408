.Token {
  max-width: 100%;
  color: var(--fgColor-muted);
  background-color: var(--bgColor-neutral-muted);
  border-color: var(--borderColor-muted);
  border-style: solid;
}

.Token:where([data-interactive='true']):hover {
  color: var(--fgColor-default);
  background-color: var(--bgColor-neutral-muted);
  box-shadow: var(--shadow-resting-medium);
}

.Token:where([data-is-selected='true']) {
  color: var(--fgColor-default);
}

.Token[data-is-remove-btn='true'] {
  padding-right: 0;
}
