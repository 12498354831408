@keyframes shimmer {
  from {
    mask-position: 200%;
  }

  to {
    mask-position: 0%;
  }
}

.ProgressBarItem {
  width: var(--progress-width);
  /* stylelint-disable-next-line primer/colors */
  background-color: var(--progress-bg);

  @media (prefers-reduced-motion: no-preference) {
    &[data-animated='true'] {
      mask-image: linear-gradient(75deg, #000 30%, rgba(0, 0, 0, 0.65) 80%);
      mask-size: 200%;
      animation-name: shimmer;
      animation-duration: 1s;
      animation-iteration-count: infinite;
    }
  }
}

.ProgressBarContainer {
  display: flex;
  overflow: hidden;
  /* stylelint-disable-next-line primer/colors */
  background-color: var(--borderColor-default);
  border-radius: var(--borderRadius-small);
  gap: 2px;

  &:where([data-progress-display='inline']) {
    display: inline-flex;
  }

  &:where([data-progress-bar-size='default']) {
    height: 8px;
  }

  &:where([data-progress-bar-size='small']) {
    height: 5px;
  }

  &:where([data-progress-bar-size='large']) {
    height: 10px;
  }
}
