.CounterLabel {
  display: inline-block;
  padding: var(--base-size-2) var(--base-size-6);
  font-size: var(--text-body-size-small);
  font-weight: var(--base-text-weight-semibold);
  /* stylelint-disable-next-line primer/typography */
  line-height: 1;
  border: var(--borderWidth-thin) solid var(--counter-borderColor);
  /* stylelint-disable-next-line primer/borders */
  border-radius: 20px;

  &:where([data-scheme='primary']) {
    color: var(--fgColor-onEmphasis);
    background-color: var(--bgColor-neutral-emphasis);
  }

  &:where([data-scheme='secondary']) {
    color: var(--fgColor-default);
    background-color: var(--bgColor-neutral-muted);
  }

  &:where(:empty) {
    display: none;
  }
}
