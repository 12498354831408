.TextArea {
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  resize: both;
  background-color: transparent;
  border: 0;
  appearance: none;
}

.TextArea:focus {
  outline: 0;
}

.TextArea[resize='none'] {
  resize: none;
}

.TextArea[resize='both'] {
  resize: both;
}

.TextArea[resize='horizontal'] {
  resize: horizontal;
}

.TextArea[resize='vertical'] {
  resize: vertical;
}

.TextArea:disabled {
  resize: none;
}
