.Input {
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  background-color: transparent;
  border: 0;
  appearance: none;

  &:focus {
    outline: 0;
  }
}
