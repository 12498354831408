.TextInputWrapper {
  padding-top: var(--base-size-6);
  padding-bottom: var(--base-size-6);
  padding-left: var(--base-size-12);

  &:where([data-block]) {
    display: flex;
    width: 100%;
  }

  &:where([data-token-wrapping]) {
    overflow: auto;
  }
}

.UnstyledTextInput {
  height: 100%;
}
