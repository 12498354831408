/* stylelint-disable selector-max-specificity */
.AvatarStack {
  --avatar-border-width: 1px;
  --overlap-size: calc(var(--avatar-stack-size) * 0.55);
  --overlap-size-avatar-three-plus: calc(var(--avatar-stack-size) * 0.85);
  --mask-size: calc(100% + (var(--avatar-border-width) * 2));
  --mask-start: -1;
  --opacity-step: 15%;

  position: relative;
  display: flex;
  min-width: var(--avatar-stack-size);
  height: var(--avatar-stack-size);
  isolation: isolate;

  &:where([data-responsive]) {
    @media screen and (--viewportRange-narrow) {
      --avatar-stack-size: var(--stackSize-narrow);
    }

    @media screen and (--viewportRange-regular) {
      --avatar-stack-size: var(--stackSize-regular);
    }

    @media screen and (--viewportRange-wide) {
      --avatar-stack-size: var(--stackSize-wide);
    }
  }

  &:where([data-avatar-count='1']) {
    .AvatarItem {
      /* stylelint-disable-next-line primer/box-shadow */
      box-shadow: 0 0 0 var(--avatar-border-width) var(--avatar-borderColor);
    }
  }

  &:where([data-avatar-count='2']) {
    /*
    MIN-WIDTH CALC FORMULA EXPLAINED:
    avatar size ➡️ var(--avatar-stack-size)
    plus the visible part of the 2nd avatar ➡️ var(--avatar-stack-size) - var(--overlap-size)
    */
    min-width: calc(var(--avatar-stack-size) + (var(--avatar-stack-size) - var(--overlap-size)));
  }

  &:where([data-avatar-count='3']) {
    /*
    MIN-WIDTH CALC FORMULA EXPLAINED:
    avatar size ➡️ var(--avatar-stack-size)
    plus the visible part of the 2nd avatar ➡️ var(--avatar-stack-size) - var(--overlap-size)
    plus the visible part of the 3rd avatar ➡️ var(--avatar-stack-size) - var(--overlap-size-avatar-three-plus)
    */
    min-width: calc(
      var(--avatar-stack-size) + (var(--avatar-stack-size) - var(--overlap-size)) +
        (var(--avatar-stack-size) - var(--overlap-size-avatar-three-plus))
    );
  }

  &:where([data-avatar-count='3+']) {
    /*
    MIN-WIDTH CALC FORMULA EXPLAINED:
    avatar size ➡️ var(--avatar-stack-size)
    plus the visible part of the 2nd avatar ➡️ var(--avatar-stack-size) - var(--overlap-size)
    plus the visible part of the 3rd AND 4th avatar ➡️ (var(--avatar-stack-size) - var(--overlap-size-avatar-three-plus)) * 2
    */
    min-width: calc(
      var(--avatar-stack-size) + (var(--avatar-stack-size) - var(--overlap-size)) +
        (var(--avatar-stack-size) - var(--overlap-size-avatar-three-plus)) * 2
    );
  }

  &:where([data-align-right]) {
    --mask-start: 1;

    direction: rtl;
  }
}

.AvatarStackBody {
  position: absolute;
  display: flex;

  &:where([data-disable-expand]) {
    position: relative;
  }
}

.AvatarItem {
  --avatarSize-regular: var(--avatar-stack-size);

  position: relative;
  display: flex;
  width: var(--avatar-stack-size);
  height: var(--avatar-stack-size);
  overflow: hidden;
  flex-shrink: 0;
  transition:
    margin 0.2s ease-in-out,
    opacity 0.2s ease-in-out,
    mask-position 0.2s ease-in-out,
    mask-size 0.2s ease-in-out;

  &:is(img) {
    /* stylelint-disable-next-line primer/box-shadow */
    box-shadow: 0 0 0 var(--avatar-border-width) transparent;
  }

  &:first-child {
    margin-inline-start: 0;
  }

  &:nth-child(n + 2) {
    /* stylelint-disable-next-line primer/spacing */
    margin-inline-start: calc(var(--overlap-size) * -1);
    mask-image: radial-gradient(at 50% 50%, rgb(0, 0, 0) 70%, rgba(0, 0, 0, 0) 71%), linear-gradient(rgb(0, 0, 0) 0 0);
    mask-repeat: no-repeat, no-repeat;
    mask-size:
      var(--mask-size) var(--mask-size),
      auto;
    mask-composite: exclude;

    /*
    HORIZONTAL POSITION CALC FORMULA EXPLAINED:
    width of the visible part of the avatar ➡️ var(--avatar-stack-size) - var(--overlap-size)
    multiply by -1 for left-aligned, 1 for right-aligned ➡️ var(--mask-start)
    subtract the avatar border width ➡️ var(--avatar-border-width)
    */
    mask-position:
      calc((var(--avatar-stack-size) - var(--overlap-size)) * var(--mask-start) - var(--avatar-border-width)) center,
      0 0;

    /* HACK: This padding fixes a weird rendering bug where a tiiiiny outline is visible at the edges of the element */
    /* stylelint-disable-next-line primer/spacing */
    padding: 0.1px;
  }

  &:nth-child(n + 3) {
    --overlap-size: var(--overlap-size-avatar-three-plus);

    /* stylelint-disable-next-line alpha-value-notation */
    opacity: calc(100% - 2 * var(--opacity-step));
  }

  &:nth-child(n + 4) {
    /* stylelint-disable-next-line alpha-value-notation */
    opacity: calc(100% - 3 * var(--opacity-step));
  }

  &:nth-child(n + 5) {
    /* stylelint-disable-next-line alpha-value-notation */
    opacity: calc(100% - 4 * var(--opacity-step));
  }

  &:nth-child(n + 6) {
    visibility: hidden;
    opacity: 0;
  }
}

.AvatarStackBody:not([data-disable-expand]):hover,
.AvatarStackBody:not([data-disable-expand]):focus-within {
  width: auto;

  .AvatarItem {
    --mask-size: 100%; /* reset size of the mask to prevent unintentially clipping due to the additional size created by the border width */

    margin-inline-start: var(--base-size-4);
    visibility: visible;
    opacity: 1;

    /*
    HORIZONTAL POSITION CALC FORMULA EXPLAINED:
    width of the full avatar ➡️ var(--avatar-stack-size)
    multiply by -1 for left-aligned, 1 for right-aligned ➡️ var(--mask-start)
    */
    mask-position:
      calc(var(--avatar-stack-size) * var(--mask-start)) center,
      0 0;

    &:first-child {
      margin-inline-start: 0;
    }
  }
}
