/* Maintain resize cursor while dragging */
/* stylelint-disable-next-line selector-no-qualifying-type */
body[data-page-layout-dragging='true'] {
  cursor: col-resize;
}

/* Disable text selection while dragging */
/* stylelint-disable-next-line selector-no-qualifying-type */
body[data-page-layout-dragging='true'] * {
  user-select: none;
}

.PageLayoutRoot {
  /* Region Order */
  --region-order-header: 0;
  --region-order-pane-start: 1;
  --region-order-content: 2;
  --region-order-pane-end: 3;
  --region-order-footer: 4;

  /* Spacing Values */
  --spacing-none: 0;
  --spacing-condensed: var(--base-size-16);
  --spacing-normal: var(--base-size-16);

  @media screen and (min-width: 1012px) {
    --spacing-normal: var(--base-size-24);
  }

  /* Pane Width Values */
  --pane-width-small: 100%;
  --pane-width-medium: 100%;
  --pane-width-large: 100%;
  --pane-max-width-diff: 511px;

  @media screen and (min-width: 768px) {
    --pane-width-small: 240px;
    --pane-width-medium: 256px;
    --pane-width-large: 256px;
  }

  @media screen and (min-width: 1012px) {
    --pane-width-small: 256px;
    --pane-width-medium: 296px;
    --pane-width-large: 320px;
  }

  @media screen and (min-width: 1280px) {
    --pane-max-width-diff: 959px;
  }

  /* These following CSS variables are dynamic values that get overridden by styles passed in via props. */
  --spacing: 0;
  --spacing-row: 0;
  --spacing-column: 0;
  --spacing-divider: 0;
  --offset-header: 0;
  --sticky-pane-height: 0;
  --pane-width: 0;
  --pane-min-width: 0;
  --pane-max-width: 0;
  --pane-width-custom: 0;
  --pane-width-size: 0;

  /* stylelint-disable-next-line primer/spacing */
  padding: var(--spacing);
}

.PageLayoutWrapper {
  display: flex;
  margin-right: auto;
  margin-left: auto;
  flex-wrap: wrap;

  &:where([data-width='medium']) {
    max-width: 768px;
  }

  &:where([data-width='large']) {
    max-width: 1012px;
  }

  &:where([data-width='full']) {
    max-width: 100%;
  }

  &:where([data-width='xlarge']) {
    max-width: 1280px;
  }
}

.PageLayoutContent {
  display: flex;
  flex: 1 1 100%;
  flex-wrap: wrap;
  max-width: 100%;
}

.HorizontalDivider {
  /* stylelint-disable-next-line primer/spacing */
  margin-right: calc(-1 * var(--spacing-divider));
  /* stylelint-disable-next-line primer/spacing */
  margin-left: calc(-1 * var(--spacing-divider));

  &:where([data-variant='none']) {
    display: none;
  }

  &:where([data-variant='line']) {
    display: block;
    height: 1px;
    /* stylelint-disable-next-line primer/colors */
    background-color: var(--borderColor-default);
  }

  &:where([data-variant='filled']) {
    display: block;
    height: var(--base-size-8);
    background-color: var(--bgColor-inset);
    box-shadow:
      /* stylelint-disable-next-line primer/box-shadow */
      inset 0 -1px 0 0 var(--borderColor-default),
      inset 0 1px 0 0 var(--borderColor-default);
  }

  @media screen and (min-width: 768px) {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
}

.VerticalDivider {
  position: relative;
  height: 100%;

  &:where([data-variant='none']) {
    display: none;
  }

  &:where([data-variant='line']) {
    display: block;
    width: 1px;
    /* stylelint-disable-next-line primer/colors */
    background-color: var(--borderColor-default);
  }

  &:where([data-variant='filled']) {
    display: block;
    width: var(--base-size-8);
    background-color: var(--bgColor-inset);
    box-shadow:
      /* stylelint-disable-next-line primer/box-shadow */
      inset -1px 0 0 0 var(--borderColor-default),
      inset 1px 0 0 0 var(--borderColor-default);
  }
}

.Header {
  width: 100%;
  /* stylelint-disable-next-line primer/spacing */
  margin-bottom: var(--spacing);
}

.HeaderContent {
  /* stylelint-disable-next-line primer/spacing */
  padding: var(--spacing);
}

.HeaderHorizontalDivider {
  /* stylelint-disable-next-line primer/spacing */
  margin-top: var(--spacing);
}

.ContentWrapper {
  display: flex;

  /* Hack to prevent overflowing content from pushing the pane region to the next line */
  min-width: 1px;
  flex-direction: column;
  order: var(--region-order-content);

  /* Set flex-basis to 0% to allow flex-grow to control the width of the content region.
      Without this, the content region could wrap onto a different line
      than the pane region on wide viewports if its contents are too wide. */
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;

  &:where([data-is-hidden='true']) {
    display: none;
  }
}

.Content {
  width: 100%;

  /* stylelint-disable-next-line primer/spacing */
  padding: var(--spacing);
  margin-right: auto;
  margin-left: auto;
  flex-grow: 1;

  &:where([data-width='medium']) {
    max-width: 768px;
  }

  &:where([data-width='large']) {
    max-width: 1012px;
  }

  &:where([data-width='full']) {
    max-width: 100%;
  }

  &:where([data-width='xlarge']) {
    max-width: 1280px;
  }
}

.PaneWrapper {
  display: flex;
  width: 100%;
  margin-right: 0;
  margin-left: 0;

  &:where([data-is-hidden='true']) {
    display: none;
  }

  &:where([data-position='end']) {
    /* stylelint-disable-next-line primer/spacing */
    margin-top: var(--spacing-row);
    flex-direction: column;
    order: var(--region-order-pane-end);
  }

  &:where([data-position='start']) {
    /* stylelint-disable-next-line primer/spacing */
    margin-bottom: var(--spacing-row);
    flex-direction: column-reverse;
    order: var(--region-order-pane-start);
  }

  @media screen and (min-width: 768px) {
    width: auto;
    margin-top: 0 !important;
    margin-bottom: 0 !important;

    &:where([data-sticky]) {
      position: sticky;
      /* stylelint-disable-next-line primer/spacing */
      top: var(--offset-header);
      max-height: var(--sticky-pane-height);
    }

    &:where([data-position='end']) {
      /* stylelint-disable-next-line primer/spacing */
      margin-left: var(--spacing-column);
      flex-direction: row-reverse;
    }

    &:where([data-position='start']) {
      /* stylelint-disable-next-line primer/spacing */
      margin-right: var(--spacing-column);
      flex-direction: row;
    }
  }
}

.PaneVerticalDivider {
  &:where([data-position='start']) {
    /* stylelint-disable-next-line primer/spacing */
    margin-left: var(--spacing);
  }

  &:where([data-position='end']) {
    /* stylelint-disable-next-line primer/spacing */
    margin-right: var(--spacing);
  }
}

.Pane {
  width: var(--pane-width-size);
  /* stylelint-disable-next-line primer/spacing */
  padding: var(--spacing);

  @media screen and (min-width: 768px) {
    overflow: auto;
  }

  &:where([data-resizable]) {
    width: 100%;

    @media screen and (min-width: 768px) {
      width: clamp(var(--pane-min-width), var(--pane-width), var(--pane-max-width));
    }
  }
}

.PaneHorizontalDivider {
  &:where([data-position='start']) {
    /* stylelint-disable-next-line primer/spacing */
    margin-top: var(--spacing);
  }

  &:where([data-position='end']) {
    /* stylelint-disable-next-line primer/spacing */
    margin-bottom: var(--spacing);
  }
}

.FooterWrapper {
  width: 100%;
  order: var(--region-order-footer);

  /* stylelint-disable-next-line primer/spacing */
  margin-top: var(--spacing);
}

.FooterHorizontalDivider {
  /* stylelint-disable-next-line primer/spacing */
  margin-bottom: var(--spacing);
}

.FooterContent {
  /* stylelint-disable-next-line primer/spacing */
  padding: var(--spacing);
}
