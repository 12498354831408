.Invisible {
  position: relative;
  padding-top: var(--base-size-2);
  padding-right: var(--base-size-4);
  padding-bottom: var(--base-size-2);
  padding-left: var(--base-size-4);
  color: var(--fgColor-muted);
  background-color: transparent;

  &:hover,
  &:focus {
    color: var(--fgColor-default);
  }

  &[data-component='IconButton'] {
    width: var(--inner-action-size);
    height: var(--inner-action-size);
  }

  @media (pointer: coarse) {
    ::after {
      position: absolute;
      top: 50%;
      right: 0;
      left: 0;
      min-height: 44px;
      content: '';
      transform: translateY(-50%);
    }
  }
}
