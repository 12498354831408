.TokenButton {
  display: inline-flex;
  padding: 0;
  margin-left: var(--base-size-4);
  font-family: inherit;
  color: currentColor;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 0;
  border-radius: var(--borderRadius-full);
  justify-content: center;
  align-items: center;
  appearance: none;
  align-self: baseline;
}

.TokenButton[data-size='small'] {
  width: var(--base-size-16);
  height: var(--base-size-16);
}

.TokenButton[data-size='medium'] {
  width: 20px;
  height: 20px;
}

.TokenButton[data-size='large'] {
  width: var(--base-size-24);
  height: var(--base-size-24);
  margin-left: var(--base-size-8);
}

.TokenButton[data-size='xlarge'] {
  width: var(--base-size-32);
  height: var(--base-size-32);
  margin-left: var(--base-size-8);
}

.TokenButton:hover,
.TokenButton:focus {
  /* TODO: choose a better functional color variable for this */
  background-color: var(--bgColor-neutral-muted);
}

.TokenButton:active {
  /* TODO: choose a better functional color variable for this */
  background-color: var(--bgColor-neutral-muted);
}
