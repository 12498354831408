.Body {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
  flex-direction: column;

  & > * + * {
    margin-top: var(--base-size-8);
  }
}

.GroupFieldset {
  padding: 0;
  margin: 0;
  border: none;

  &:where([data-validation]) {
    margin-bottom: var(--base-size-8);
  }
}

.GroupLegend {
  padding: 0;

  &:where([data-legend-visible]) {
    margin-bottom: var(--base-size-8);
  }
}

.CheckboxOrRadioGroupCaption {
  font-size: var(--text-body-size-medium);
  color: var(--fgColor-muted);
}

.RadioGroupLabel {
  display: block;
  font-size: var(--text-body-size-medium);
  font-weight: var(--base-text-weight-semibold);

  &:where([data-label-disabled]) {
    color: var(--fgColor-muted);
  }
}

.GroupLabelChildren {
  margin-right: var(--base-size-8);
}
