.Page {
  display: inline-block;
  min-width: 32px; /* primer.control.medium.size */
  height: 32px; /* primer.control.medium.size */
  padding: var(--base-size-8) calc((var(--base-size-32) - var(--base-size-20)) / 2); /* primer.control.medium.paddingInline.condensed primer.control.medium.paddingBlock */
  margin-right: var(--base-size-4);
  font-style: normal;
  /* stylelint-disable-next-line primer/typography */
  line-height: 1;
  color: var(--fgColor-default);
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border-radius: var(--borderRadius-medium);
  transition: background-color 0.2s cubic-bezier(0.3, 0, 0.5, 1);
}

@supports (clip-path: polygon(50% 0, 100% 50%, 50% 100%)) {
  .Page[rel='prev']::before,
  .Page[rel='next']::after {
    display: inline-block;
    width: 16px;
    height: 16px;
    vertical-align: text-bottom;
    content: '';
    background-color: currentColor;
  }

  /* chevron-left */
  .Page[rel='prev']::before {
    margin-right: var(--base-size-4);
    clip-path: polygon(
      9.8px 12.8px,
      8.7px 12.8px,
      4.5px 8.5px,
      4.5px 7.5px,
      8.7px 3.2px,
      9.8px 4.3px,
      6.1px 8px,
      9.8px 11.7px,
      9.8px 12.8px
    );
  }

  /* chevron-right */
  .Page[rel='next']::after {
    margin-left: var(--base-size-4);
    clip-path: polygon(
      6.2px 3.2px,
      7.3px 3.2px,
      11.5px 7.5px,
      11.5px 8.5px,
      7.3px 12.8px,
      6.2px 11.7px,
      9.9px 8px,
      6.2px 4.3px,
      6.2px 3.2px
    );
  }
}

.Page:last-child {
  margin-right: 0;
}

.Page:hover,
.Page:focus {
  text-decoration: none;
  background-color: var(--control-transparent-bgColor-hover);
  outline: 0;
  transition-duration: 0.1s;
}

.Page:focus-visible {
  outline: 2px solid var(--bgColor-accent-emphasis);
  outline-offset: -2px;
}

.Page:active {
  border-color: var(--borderColor-muted);
}

.Page[rel='prev'],
.Page[rel='next'] {
  color: var(--fgColor-accent);
}

.Page[aria-current],
.Page[aria-current]:hover {
  color: var(--fgColor-onEmphasis);
  background-color: var(--bgColor-accent-emphasis);
  border-color: transparent;
}

.Page[aria-current]:focus-visible {
  outline: 2px solid var(--bgColor-accent-emphasis);
  outline-offset: -2px;
  /* stylelint-disable-next-line primer/box-shadow */
  box-shadow: inset 0 0 0 3px var(--fgColor-onEmphasis);
}

.Page[aria-hidden],
.Page[aria-hidden]:hover,
.Page[role='presentation'],
.Page[role='presentation']:hover {
  color: var(--fgColor-disabled);
  cursor: default;
  background-color: transparent;
}

.PaginationContainer {
  /* stylelint-disable-next-line primer/spacing */
  margin-top: 20px;
  /* stylelint-disable-next-line primer/spacing */
  margin-bottom: 15px;
  text-align: center;
}

@media screen and (--viewportRange-narrow) {
  .TablePaginationSteps[data-hidden-viewport-ranges*='narrow'] > *:not(:first-child):not(:last-child) {
    display: none;
  }

  .TablePaginationSteps[data-hidden-viewport-ranges*='narrow'] > *:first-child {
    margin-inline-end: 0;
  }

  .TablePaginationSteps[data-hidden-viewport-ranges*='narrow'] > *:last-child {
    margin-inline-start: 0;
  }
}

@media screen and (--viewportRange-regular) {
  .TablePaginationSteps[data-hidden-viewport-ranges*='regular'] > *:not(:first-child):not(:last-child) {
    display: none;
  }

  .TablePaginationSteps[data-hidden-viewport-ranges*='regular'] > *:first-child {
    margin-inline-end: 0;
  }

  .TablePaginationSteps[data-hidden-viewport-ranges*='regular'] > *:last-child {
    margin-inline-start: 0;
  }
}

@media screen and (--viewportRange-wide) {
  .TablePaginationSteps[data-hidden-viewport-ranges*='wide'] > *:not(:first-child):not(:last-child) {
    display: none;
  }

  .TablePaginationSteps[data-hidden-viewport-ranges*='wide'] > *:first-child {
    margin-inline-end: 0;
  }

  .TablePaginationSteps[data-hidden-viewport-ranges*='wide'] > *:last-child {
    margin-inline-start: 0;
  }
}
