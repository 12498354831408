.Spinner {
  position: absolute;
  top: 0;
  right: 0;
  max-width: 100%;
  height: 100%;
}

.SpinnerLeading {
  left: 0;
}

.SpinnerHidden {
  visibility: hidden;
}

.SpinnerVisible {
  visibility: visible;
}
