.ControlHorizontalLayout {
  display: flex;

  &:where([data-has-leading-visual]) {
    align-items: center;
  }
}

.ControlVerticalLayout {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & > *:not(label) + * {
    margin-top: var(--base-size-4);
  }

  &[data-has-label] > * + * {
    margin-top: var(--base-size-4);
  }
}

.ControlChoiceInputs > input {
  margin-right: 0;
  margin-left: 0;
}

.LabelContainer {
  > * {
    /* stylelint-disable-next-line primer/spacing */
    padding-left: var(--stack-gap-condensed);
  }

  > label {
    font-weight: var(--base-text-weight-normal);
  }
}

.LeadingVisual {
  margin-left: var(--base-size-8);
  color: var(--fgColor-muted);

  &:where([data-disabled]) {
    color: var(--control-fgColor-disabled);
  }

  > * {
    min-width: var(--text-body-size-large);
    min-height: var(--text-body-size-large);
    fill: currentColor;
  }

  > *:where([data-has-caption]) {
    min-width: var(--base-size-24);
    min-height: var(--base-size-24);
  }
}
